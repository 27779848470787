import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './home.css';
import bannerImage from '../../images/Banner.png'; // The correct banner image
import opiusagent from '../../images/OpiusAgent.png';
import opiussecurity from '../../images/opiusSecurity.png';
import opiusmarket from '../../images/OpiusMarketing.gif';
import opiusmarketing from '../../images/OpiusMarket.gif';

const Home = () => {
  return (
    <div className="homepage bg-light" >
      {/* Banner Section */}
      <Container
        fluid
        className="home-banner d-flex align-items-start align-items-md-center"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <Row className="w-100">
          <Col md={6} className="home-content">
            <h1 className="home-h1">Empowering Healthcare With AI</h1>
          </Col>
        </Row>
      </Container>

      {/* Features Sections */}
      <section>
        {/*
        <Container className="py-5 bg-light">
          <Row className="align-items-center home-feature-row home-feature-row-even">
            <Col lg={6}>
              <h2 className="home-features-heading">Opius AI Customer Engagement</h2>
              <ul className="home-features-list">
                <li><strong>Personalized Customer Experience:</strong> Opius AI Agent creates a personalized experience for each customer, enhancing engagement and satisfaction.</li>
                <li><strong>Integration:</strong> Our AI Agent seamlessly integrates with your existing platforms, ensuring consistent and effective communication with customers.</li>
                <li><strong>Natural Flow of Conversation:</strong> The intuitive design of Opius AI Agent enables it to understand and respond to customer queries naturally, fostering trust and connection.</li>
                <li><strong>Boosts Customer Engagement:</strong> By providing real-time, accurate responses, Opius AI Agent boosts customer engagement and drives meaningful interactions.</li>
              </ul>
            </Col>
            <Col lg={6} className="mb-4">
              <img src={opiusagent} className="img-fluid home-elegant-img" alt="Opius AI Customer Engagement" />
            </Col>
          </Row>
        </Container>

        <Container className="py-5 bg-light">
          <Row className="align-items-center home-feature-row home-feature-row-odd">
            <Col lg={6} className="mb-4">
              <img src={opiusmarket} className="img-fluid home-elegant-img" alt="Opius AI Assistant" />
            </Col>
            <Col lg={6}>
              <h2 className="home-features-heading">Opius AI Assistant</h2>
              <ul className="home-features-list">
                <li><strong>Robust Data Encryption:</strong> Implement end-to-end encryption to ensure that all customer data and communications are securely protected during transmission and storage.</li>
                <li><strong>Compliance with Industry Standards:</strong> Design the AI assistant to comply with key data protection regulations, such as GDPR, HIPAA, and CCPA, ensuring that businesses meet legal obligations.</li>
                <li><strong>Privacy-First Approach:</strong> Prioritize user privacy by minimizing data collection, anonymizing sensitive information, and offering customers full control over their data.</li>
                <li><strong>Role-Based Access Control:</strong> Limit data access based on user roles within the business, ensuring that only authorized personnel can access sensitive information.</li>
                <li><strong>User Consent Management:</strong> Incorporate features that allow businesses to easily obtain, track, and manage user consent for data processing, ensuring compliance and transparency.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        */}
        
        <Container className="py-5 bg-light">
          <Row className="align-items-center home-feature-row home-feature-row-even">
            <Col lg={6}>
              <h2 className="home-features-heading">Data Security</h2>
              <ul className="home-features-list">
                <li>Opius AI ensures the highest level of data security, protecting your business and customer information with advanced encryption protocols.</li>
                <li>Our platform is built with rigorous security measures, safeguarding all data interactions against unauthorized access.</li>
                <li>With Opius AI, data integrity and confidentiality are paramount, ensuring that your sensitive information is always secure.</li>
                <li>Opius AI employs cutting-edge security technologies to prevent breaches, offering peace of mind for your business operations.</li>
                <li>We are committed to maintaining compliance with global data protection standards, ensuring your data is secure and in safe hands.</li>
              </ul>
            </Col>
            <Col lg={6} className="mb-4">
              <img src={opiussecurity} className="img-fluid home-elegant-img" alt="Data Security" />
            </Col>
          </Row>
        </Container>

        <Container className="py-5 bg-light">
          <Row className="align-items-center home-feature-row home-feature-row-odd">
            <Col lg={6} className="mb-4">
              <img src={opiusmarketing} className="img-fluid home-elegant-img" alt="Opius AI Analytics" />
            </Col>
            <Col lg={6}>
              <h2 className="home-features-heading">Opius AI Analytics</h2>
              <ul className="home-features-list">
                <li><strong>Real-Time Data Visualization:</strong> Provide businesses with real-time analytics through an intuitive dashboard, enabling quick decision-making and strategy adjustments.</li>
                <li><strong>Customizable Reports:</strong> Allow users to generate customizable reports based on key metrics, ensuring they can focus on the data that matters most to their business.</li>
                <li><strong>AI-Driven Insights:</strong> Offer AI-generated insights and recommendations based on data trends, helping businesses optimize their operations and marketing efforts.</li>
                <li><strong>Integration with Existing Tools:</strong> Seamlessly integrate the analytics dashboard with existing business tools like CRM, ERP, and marketing platforms, ensuring a cohesive data ecosystem.</li>
                <li><strong>User-Friendly Interface:</strong> Design the dashboard to be user-friendly, with easy navigation and customization options, making it accessible to users of all technical skill levels.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Home;