import React from 'react';
import opiousLogo from '../../images/opius_logo.png';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <Link to="/">
          <img src={opiousLogo} alt="logo" />
        </Link> 
        <h2> Opius AI </h2>
        <p>30 N Gould St Ste R Sheridan, WY 82801</p>
      </div>


      <div className="gpt3__footer-links_div">
        <h4>Support</h4>
        <div className="gpt3__footer-links_columns">
          <div>
            <p>Help Center</p>
            <p>Privacy Policy</p>
          </div>
          <div>
            <p>Terms & Conditions</p>
            <Link to="/contact"><p>Contact us</p></Link>
          </div>
        </div>
      </div>

    </div>
    <div className="gpt3__footer-copyright">
      <p>© 2024 Opius. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;