import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ChatContent.css'; // Import the CSS for styling
import chatGif from '../../../images/chat-demo.gif'; 
import chatfeatures from '../../../images/ChatFeatures.png';
import chatcost from '../../../images/ChatCost.png';
import chatlaunch from '../../../images/ChatLaunch.png';
import chatAgentBanner from '../../../images/OpiusAgentBanner.png';

const ChatContent = () => {
  return (
    <div className="chat-content bg-light">
      <Container
        fluid
        className="chat-banner d-flex align-items-start align-items-md-center"
        style={{ backgroundImage: `url(${chatAgentBanner})` }}
      >
        <Row className="w-100">
          <Col md={6} className="chat-content">
            <h1 className="chat-h1">AI Driven Business Growth</h1>
          </Col>
        </Row>
      </Container>

      <section className="chat-features-section-odd">
        <Container className='py-5 bg-light'>
          <Row className="align-items-stretch">
            <Col lg={6} className="d-flex flex-column justify-content-center order-lg-1 order-2">
              <h2 className="chat-features-heading">Opius AI Agent</h2>
              <ul className="chat-features-list">
                <li><strong>Human Like Conversations:</strong> Opius AI Agent delivers human-like interactions, ensuring customers feel understood and valued in every conversation.</li>
                <li>Conversations with Opius AI flow naturally, making it difficult to distinguish between AI and human responses.</li>
                <li><strong>Personalized Responses:</strong> Our AI Agent adapts to the user's tone and context, providing personalized and intuitive responses.</li>
                <li>Opius AI Agent excels at handling complex queries with ease, ensuring a seamless customer experience.</li>
                <li><strong>Enhanced Customer Engagement:</strong> Designed to enhance engagement, Opius AI Agent responds intelligently, improving customer satisfaction and efficiency.</li>
              </ul>
            </Col>
            <Col lg={6} className="d-flex order-lg-2 order-1">
              <div className="chat-image-container">
                <img src={chatGif} className='img-fluid chat-gif' alt="Opius AI Agent" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="chat-features-section-even">
        <Container className='py-5 bg-light'>
          <Row className="align-items-stretch">
            <Col lg={6} className="d-flex order-lg-1 order-2">
              <div className="chat-image-container">
                <img src={chatfeatures} className='img-fluid chat-gif' alt="Key Features" />
              </div>
            </Col>
            <Col lg={6} className="d-flex flex-column justify-content-center order-lg-2 order-1">
              <h2 className="chat-features-heading">Key Features of Our Solution</h2>
              <ul className="chat-features-list">
                <li><strong>24/7 Availability:</strong> Our chatbot is always ready to assist your customers, day or night.</li>
                <li><strong>Instant Responses:</strong> Provide immediate answers to customer inquiries, improving satisfaction and retention.</li>
                <li><strong>Multilingual Support:</strong> Communicate with customers in multiple languages to expand your reach.</li>
                <li><strong>Personalized Interactions:</strong> Tailor responses based on customer data for a more personalized experience.</li>
                <li><strong>Seamless Integration:</strong> Easily integrate our chatbot into your existing website or app.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="chat-features-section-odd">
        <Container className='py-5 bg-light'>
          <Row className="align-items-stretch">
            <Col lg={6} className="d-flex flex-column justify-content-center order-lg-1 order-2">
              <h2 className="chat-features-heading">Key Benefits of Our Solution</h2>
              <ul className="chat-features-list">
                <li><strong>Increased Efficiency:</strong> Automate repetitive tasks and free up your team for more complex issues.</li>
                <li><strong>Cost Savings:</strong> Reduce operational costs by handling more inquiries without additional staff.</li>
                <li><strong>Improved Customer Satisfaction:</strong> Deliver quick and accurate responses, enhancing the customer experience.</li>
                <li><strong>Scalable Solution:</strong> Handle a growing number of customer interactions without compromising quality.</li>
              </ul>
            </Col>
            <Col lg={6} className="d-flex order-lg-2 order-1">
              <div className="chat-image-container">
                <img src={chatcost} className='img-fluid chat-gif' alt="Key Benefits" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="chat-features-section-even">
        <Container className='py-5 bg-light'>
          <Row className="align-items-stretch">
            <Col lg={6} className="d-flex order-lg-1 order-2">
              <div className="chat-image-container">
                <img src={chatlaunch} className='img-fluid chat-gif' alt="How it Works" />
              </div>
            </Col>
            <Col lg={6} className="d-flex flex-column justify-content-center order-lg-2 order-1">
              <h2 className="chat-features-heading">How it Works</h2>
              <ul className="chat-features-list">
                <li><strong>Setup:</strong> Integrate the chatbot with your website or app using our easy-to-follow setup guide.</li>
                <li><strong>Customization:</strong> Customize the chatbot's responses and appearance to match your brand.</li>
                <li><strong>Launch:</strong> Deploy the chatbot and start engaging with your customers immediately.</li>
                <li><strong>Monitor and Improve:</strong> Use our analytics tools to monitor performance and continuously improve the chatbot's responses.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default ChatContent;