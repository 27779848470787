import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './profile.css';

const Profile = () => {
    return (
        <Container className="profile-container">
            {/* Profile Header */}
            <header className="profile-header text-center">
                <h1>User Profile</h1>
                <p>Manage your account settings and personal information.</p>
            </header>

            {/* Profile Info Section */}
            <section className="profile-info mb-5">
                <Card className="profile-card">
                    <Card.Body>
                        <h2 className="section-title">Personal Information</h2>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="formFirstName" className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your first name" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formLastName" className="mb-3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your last name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter your email" />
                            </Form.Group>
                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="tel" placeholder="Enter your phone number" />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </section>

            {/* Security Section */}
            <section className="security-section mb-5">
                <Card className="profile-card">
                    <Card.Body>
                        <h2 className="section-title">Security Settings</h2>
                        <Form>
                            <Form.Group controlId="formCurrentPassword" className="mb-3">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter current password" />
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="formNewPassword" className="mb-3">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter new password" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formConfirmPassword" className="mb-3">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm new password" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit">
                                Update Password
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </section>

            {/* Preferences Section */}
            <section className="preferences-section">
                <Card className="profile-card">
                    <Card.Body>
                        <h2 className="section-title">Preferences</h2>
                        <Form>
                            <Form.Group controlId="formLanguage" className="mb-3">
                                <Form.Label>Language</Form.Label>
                                <Form.Control as="select" defaultValue="English">
                                    <option>English</option>
                                    <option>Spanish</option>
                                    <option>French</option>
                                    <option>German</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formTimezone" className="mb-3">
                                <Form.Label>Timezone</Form.Label>
                                <Form.Control as="select" defaultValue="GMT">
                                    <option>GMT</option>
                                    <option>PST</option>
                                    <option>EST</option>
                                    <option>CET</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Preferences
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </section>
        </Container>
    );
};

export default Profile;