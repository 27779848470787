import React from 'react';
import { Container, Row, Col, Card, Form, Button, ListGroup } from 'react-bootstrap';
import './settings.css';

const Settings = () => {
    return (
        <Container className="settings-container">
            <Row>
                {/* Sidebar */}
                <Col md={3}>
                    <Card className="settings-sidebar">
                        <ListGroup variant="flush">
                            <ListGroup.Item action href="#general-settings">General Settings</ListGroup.Item>
                            <ListGroup.Item action href="#account-settings">Account Settings</ListGroup.Item>
                            <ListGroup.Item action href="#integration-settings">Integration Settings</ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>

                {/* Settings Content */}
                <Col md={9}>
                    <Card className="settings-content" id="general-settings">
                        <Card.Body>
                            <h2 className="section-title">General Settings</h2>
                            <Form>
                                <Form.Group controlId="formAppTheme" className="mb-3">
                                    <Form.Label>Application Theme</Form.Label>
                                    <Form.Control as="select" defaultValue="Dark">
                                        <option>Dark</option>
                                        <option>Light</option>
                                        <option>System Default</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formNotifications" className="mb-3">
                                    <Form.Label>Notifications</Form.Label>
                                    <Form.Check 
                                        type="switch"
                                        id="notifications-switch"
                                        label="Enable Notifications"
                                        defaultChecked
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Save General Settings
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>

                    <Card className="settings-content mt-4" id="account-settings">
                        <Card.Body>
                            <h2 className="section-title">Account Settings</h2>
                            <Form>
                                <Form.Group controlId="formUsername" className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your username" />
                                </Form.Group>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter your email" />
                                </Form.Group>
                                <Form.Group controlId="formDeleteAccount" className="mb-3">
                                    <Button variant="danger">
                                        Delete My Account
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>

                    <Card className="settings-content mt-4" id="integration-settings">
                        <Card.Body>
                            <h2 className="section-title">Integration Settings</h2>
                            <Form>
                                <Form.Group controlId="formApiKeys" className="mb-3">
                                    <Form.Label>API Keys</Form.Label>
                                    <Form.Control type="text" placeholder="Generate or view your API keys" readOnly />
                                    <Button variant="secondary" className="mt-2">Generate New Key</Button>
                                </Form.Group>
                                <Form.Group controlId="formWebhooks" className="mb-3">
                                    <Form.Label>Webhooks</Form.Label>
                                    <Form.Control type="text" placeholder="Enter webhook URL" />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Save Integration Settings
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Settings;