import React from 'react';
import ChatContent from './ChatContent/ChatContent';
import ChatWindow from './window/ChatWindow';

const ChatAgent = () => {
  const sendMessage = async (userInput) => {
    if (userInput.trim() === '') return '';

    try {
      const response = await fetch('https://api.opiusai.com/chatagent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userInput }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.response.replace(/\n/g, '<br>'); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error:', error);
      return 'Sorry, something went wrong. Please try again later.';
    }
  };

  return (
    <div>
      <ChatContent />
      <ChatWindow sendMessage={sendMessage} />
    </div>
  );
};

export default ChatAgent;