import React, { useState, useEffect } from 'react';
import './billing.css';

const Billing = () => {
    // State hooks for managing various billing details
    const [subscription, setSubscription] = useState({
        planName: 'Pro Plan',
        price: 49.99,
        billingCycle: 'Monthly',
        nextBillingDate: '2024-09-15',
        features: ['Unlimited projects', 'Priority support', 'Advanced analytics'],
    });
    
    const [paymentMethod, setPaymentMethod] = useState({
        cardType: 'Visa',
        lastFour: '1234',
        billingAddress: '1234 Street, City, State, ZIP',
        expiry: '12/26',
        name: 'John Doe',
    });

    const [billingHistory, setBillingHistory] = useState([
        { date: '2024-08-15', amount: 49.99, status: 'Paid', invoiceUrl: '/path/to/invoice1.pdf' },
        { date: '2024-07-15', amount: 49.99, status: 'Paid', invoiceUrl: '/path/to/invoice2.pdf' },
        { date: '2024-06-15', amount: 49.99, status: 'Paid', invoiceUrl: '/path/to/invoice3.pdf' },
    ]);

    const [upcomingCharges, setUpcomingCharges] = useState({
        amount: 49.99,
        taxes: 5.00,
        total: 54.99,
    });

    const [discount, setDiscount] = useState({
        code: 'SUMMER21',
        amount: 10.00,
        description: '10% off for the summer sale!',
    });

    // Simulating fetching data on component mount
    useEffect(() => {
        // Here you would fetch billing data from your API
        // For now, we are using the hardcoded state above
    }, []);

    return (
        <div className="billing-page">
            <div className="billing-header">
                <h1>Billing & Subscription</h1>
                <p>Manage your subscription, payment methods, and billing history.</p>
            </div>

            {/* Subscription Details */}
            <section className="subscription-details">
                <h2>Subscription Details</h2>
                <div className="subscription-card">
                    <h3>{subscription.planName}</h3>
                    <p className="price">${subscription.price} / {subscription.billingCycle}</p>
                    <p className="next-billing">Next Billing Date: {subscription.nextBillingDate}</p>
                    <ul className="features">
                        {subscription.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                    <button className="btn-primary">Upgrade/Downgrade Plan</button>
                </div>
            </section>

            {/* Payment Information */}
            <section className="payment-information">
                <h2>Payment Information</h2>
                <div className="payment-card">
                    <div className="card-details">
                        <div className="card-info">
                            <h3>{paymentMethod.cardType} ending in {paymentMethod.lastFour}</h3>
                            <p>Card Holder: {paymentMethod.name}</p>
                            <p>Expiry: {paymentMethod.expiry}</p>
                        </div>
                        <div className="billing-address">
                            <h4>Billing Address</h4>
                            <p>{paymentMethod.billingAddress}</p>
                        </div>
                    </div>
                    <button className="btn-primary">Update Payment Method</button>
                </div>
            </section>

            {/* Billing History */}
            <section className="billing-history">
                <h2>Billing History</h2>
                <div className="history-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billingHistory.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.date}</td>
                                    <td>${entry.amount.toFixed(2)}</td>
                                    <td className={entry.status.toLowerCase()}>{entry.status}</td>
                                    <td><a href={entry.invoiceUrl} target="_blank" rel="noopener noreferrer">Download</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            {/* Upcoming Charges */}
            <section className="upcoming-charges">
                <h2>Upcoming Charges</h2>
                <div className="charges-card">
                    <p>Subscription: ${upcomingCharges.amount.toFixed(2)}</p>
                    <p>Taxes: ${upcomingCharges.taxes.toFixed(2)}</p>
                    <p className="total">Total: ${upcomingCharges.total.toFixed(2)}</p>
                </div>
            </section>

            {/* Discounts & Coupons */}
            <section className="discounts-coupons">
                <h2>Discounts & Coupons</h2>
                <div className="discount-card">
                    <h3>{discount.code}</h3>
                    <p>{discount.description}</p>
                    <p>Discount: -${discount.amount.toFixed(2)}</p>
                    <button className="btn-secondary">Apply New Coupon</button>
                </div>
            </section>

            {/* Cancellation Options */}
            <section className="cancellation-options">
                <h2>Cancellation & Refunds</h2>
                <p>If you need to cancel your subscription, click the button below.</p>
                <button className="btn-danger">Cancel Subscription</button>
                <p className="refund-policy">Refund Policy: Full refund available within 14 days of payment.</p>
            </section>

            {/* Customer Support */}
            <section className="customer-support">
                <h2>Need Help?</h2>
                <p>If you have any questions or issues with billing, please contact our support team.</p>
                <button className="btn-primary">Contact Support</button>
            </section>

            {/* Account Balance */}
            <section className="account-balance">
                <h2>Account Balance</h2>
                <div className="balance-card">
                    <p>Outstanding Balance: $0.00</p>
                    <button className="btn-primary">Pay Now</button>
                </div>
            </section>

            {/* Auto-Renewal Settings */}
            <section className="auto-renewal-settings">
                <h2>Auto-Renewal Settings</h2>
                <label className="switch">
                    <input type="checkbox" checked />
                    <span className="slider round"></span>
                </label>
                <p>Enable or disable auto-renewal for your subscription.</p>
            </section>

            {/* Notifications */}
            <section className="billing-notifications">
                <h2>Billing Notifications</h2>
                <p>Manage your notification preferences for billing alerts.</p>
                <button className="btn-primary">Manage Notifications</button>
            </section>

            {/* Tax Information */}
            <section className="tax-information">
                <h2>Tax Information</h2>
                <p>Update your tax information to ensure correct billing.</p>
                <button className="btn-primary">Update Tax Info</button>
            </section>
        </div>
    );
};

export default Billing;