import React from 'react';
import './InvestWithOpius.css';

const InvestWithOpius = () => {
  return (
    <div className="container invest-opius-page">
      <header className="text-center py-5">
        <h1 className="display-4">Invest with Opius</h1>
        <h3 className="lead">Join us on our mission to revolutionize AI-driven solutions for businesses worldwide.</h3>
      </header>
      
      <section className="company-overview py-5">
        <h2 className="text-center">Company Overview</h2>
        <p className="text-center">Opius LLC is at the forefront of AI innovation, developing cutting-edge solutions to enhance data protection, sales, marketing, and voice assistance. Our mission is to empower businesses with AI-driven tools that streamline operations and drive growth.</p>
      </section>
      
      <section className="market-opportunity py-5 bg-light">
        <h2 className="text-center">Market Opportunity</h2>
        <p className="text-center">The AI market is projected to reach $500 billion by 2027, with substantial growth opportunities in sectors such as data protection, marketing, and voice assistance. Opius is uniquely positioned to capitalize on this market with our innovative products.</p>
      </section>
      
      <section className="product-details py-5">
        <h2 className="text-center">Product Details</h2>
        <div className="row">
          <div className="col-md-4">
            <h3>AI Data Protection</h3>
            <p>Our AI-powered data protection solutions ensure robust security and compliance for businesses, safeguarding sensitive information.</p>
          </div>
          <div className="col-md-4">
            <h3>Sales & Marketing Enhancement</h3>
            <p>Our AI-driven tools optimize marketing strategies and improve sales outcomes through personalized interactions and data analytics.</p>
          </div>
          <div className="col-md-4">
            <h3>AI Voice Assistant</h3>
            <p>Our AI voice assistant offers seamless customer support and engagement, enhancing user experience and operational efficiency.</p>
          </div>
        </div>
      </section>
      
      <section className="team py-5 bg-light">
        <h2 className="text-center">Our Team</h2>
        <p className="text-center">Our team is composed of industry experts with decades of experience in AI development, data security, and business growth. Together, we are driving the future of AI innovation.</p>
      </section>
      
      <section className="financial-overview py-5">
        <h2 className="text-center">Financial Overview</h2>
        <p className="text-center">With a strong revenue model and strategic growth plans, Opius is positioned for long-term success. Our financial projections show significant growth potential in the coming years.</p>
      </section>
      
      <section className="investment-opportunity py-5 bg-light">
        <h2 className="text-center">Investment Opportunity</h2>
        <p className="text-center">We are seeking strategic partners and investors to join us on this exciting journey. Your investment will fuel our growth, allowing us to scale our products and expand our market reach.</p>
        <div className="text-center mt-4">
          <a href="mailto:invest@opiusai.com" className="btn btn-primary btn-lg">Get in Touch</a>
        </div>
      </section>
      
      <footer className="text-center py-4">
        <h7 className="mb-0">Opius LLC © 2024. All rights reserved.</h7>
      </footer>
    </div>
  );
};

export default InvestWithOpius;