import React from 'react';
import './Documentation.css';

const Documentation = () => {
  return (
    <div className="documentation-page">
      <h1>Documentation</h1>
      <section className="doc-section">
        <h2>Introduction</h2>
        <p>Welcome to the OPIUS AI documentation. Here you will find all the information you need to get started with our services, integrate our APIs, and understand the features we offer. Whether you're a developer looking to build intelligent applications or a business aiming to leverage AI to drive growth, our comprehensive documentation has got you covered.</p>
      </section>
      <section className="doc-section">
        <h2>Getting Started</h2>
        <p>Follow these steps to set up your account and start using OPIUS AI:</p>
        <ul>
          <li><strong>Create an account:</strong> Visit our <a href="/signup">signup page</a> and fill out the registration form to create a new account.</li>
          <li><strong>Verify your email address:</strong> Check your email for a verification link. Click the link to verify your email address and activate your account.</li>
          <li><strong>Generate your API keys:</strong> Once your account is activated, log in to your dashboard and navigate to the API keys section. Generate a new API key to authenticate your requests.</li>
          <li><strong>Explore our dashboard:</strong> Familiarize yourself with the dashboard where you can configure settings, monitor usage, and access analytics.</li>
        </ul>
      </section>
      <section className="doc-section">
        <h2>API Integration</h2>
        <p>Our APIs allow you to seamlessly integrate OPIUS AI into your applications. The APIs are designed to be easy to use and provide powerful capabilities to enhance your applications with AI functionalities.</p>
        <h3>Authentication</h3>
        <p>To authenticate your API requests, include your API key in the headers of your HTTP requests as shown below:</p>
        <pre>{`curl -X GET "https://api.opiusai.com/v1/resource" -H "Authorization: Bearer YOUR_API_KEY"`}</pre>
        <h3>Endpoints</h3>
        <div>
          <h4>GET /v1/resources</h4>
          <p>Retrieve a list of resources.</p>
          <pre>{`curl -X GET "https://api.opiusai.com/v1/resources" -H "Authorization: Bearer YOUR_API_KEY"`}</pre>
          <h4>POST /v1/resources</h4>
          <p>Create a new resource.</p>
          <pre>{`curl -X POST "https://api.opiusai.com/v1/resources" \\ -H "Authorization: Bearer YOUR_API_KEY" \\ -H "Content-Type: application/json" \\ -d '{ "name": "New Resource", "description": "Description of the new resource." }'`}</pre>
        </div>
      </section>
      <section className="doc-section">
        <h2>FAQ</h2>
        <h3>What is OPIUS AI?</h3>
        <p>OPIUS AI is a platform that provides AI-powered solutions to enhance your applications and business processes.</p>
        <h3>How do I get started?</h3>
        <p>To get started, create an account, verify your email, generate your API keys, and explore our documentation and dashboard.</p>
        <h3>Where can I find my API key?</h3>
        <p>Your API keys can be generated and managed from the API keys section in your dashboard.</p>
        <h3>Who can I contact for support?</h3>
        <p>If you need further assistance, please contact our support team at <a href="mailto:support@opiusai.com">support@opiusai.com</a>.</p>
      </section>
    </div>
  );
};

export default Documentation;
