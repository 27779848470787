import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import './support.css';

const Support = () => (
    <Container className="support-container">
        {/* Page Header */}
        <header className="support-header text-center mb-4">
            <h1>Support Center</h1>
            <p>Find help, get answers, and contact support for assistance.</p>
        </header>

        {/* FAQ Section */}
        <section className="faq-section mb-5">
            <h2>Frequently Asked Questions</h2>
            <Card className="mb-3">
                <Card.Header>How do I integrate the OPIUS AI Chatbot with my website?</Card.Header>
                <Card.Body>
                    You can integrate the OPIUS AI Chatbot with your website by following our integration guide available in the documentation section.
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>How do I monitor the performance of the AI Voice Assistant?</Card.Header>
                <Card.Body>
                    Performance metrics can be monitored through the dashboard, where you can see real-time data and historical analytics.
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>What kind of data protection does OPIUS AI offer?</Card.Header>
                <Card.Body>
                    OPIUS AI provides advanced encryption and security protocols to protect your data. Detailed information is available in the documentation.
                </Card.Body>
            </Card>
        </section>

        {/* Contact Support Section */}
        <section className="contact-section mb-5">
            <h2>Contact Support</h2>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="formName" className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId="formSubject" className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" placeholder="Enter the subject" />
                </Form.Group>
                <Form.Group controlId="formMessage" className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={4} placeholder="Describe your issue or question" />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </section>

        {/* Documentation Section */}
        <section className="documentation-section mb-5">
            <h2>Documentation</h2>
            <ul>
                <li><a href="/docs/chatbot">OPIUS AI Chatbot Integration Guide</a></li>
                <li><a href="/docs/voice-assistant">AI Voice Assistant User Manual</a></li>
                <li><a href="/docs/data-protection">Data Protection Overview</a></li>
                <li><a href="/docs/api-reference">API Reference Documentation</a></li>
            </ul>
        </section>

        {/* Support Tickets Section */}
        <section className="tickets-section">
            <h2>Your Support Tickets</h2>
            <p>Track the status of your support requests.</p>
            <ul>
                <li>Ticket #12345 - <strong>Open</strong> - Issue with API Rate Limits</li>
                <li>Ticket #12346 - <strong>Resolved</strong> - Difficulty Integrating Chatbot</li>
                <li>Ticket #12347 - <strong>In Progress</strong> - API Documentation Clarification</li>
            </ul>
            <Button variant="secondary">Create New Ticket</Button>
        </section>
    </Container>
);

export default Support;