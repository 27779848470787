import React from 'react';
import './dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            {/* Tenant Overview */}
            <header className="dashboard-header">
                <h1>Dashboard</h1>
                <p>Monitor and manage your API usage and performance.</p>
            </header>

            <section className="tenant-overview">
                <h2>Overview</h2>
                <div className="overview-cards">
                    <div className="card">
                        <h3>Subscription Plan</h3>
                        <p>Pro Plan</p>
                    </div>
                    <div className="card">
                        <h3>API Calls This Month</h3>
                        <p>23,456</p>
                    </div>
                    <div className="card">
                        <h3>API Quota Remaining</h3>
                        <p>76,544</p>
                    </div>
                    <div className="card">
                        <h3>Next Billing Date</h3>
                        <p>2024-09-15</p>
                    </div>
                </div>
            </section>

            {/* API Performance Metrics */}
            <section className="api-performance">
                <h2>API Performance Metrics</h2>
                <div className="performance-cards">
                    <div className="card">
                        <h3>Chat AI Agent API</h3>
                        <p>Total Calls: 15,678</p>
                        <p>Average Response Time: 1.2 seconds</p>
                        <p>Success Rate: 98.5%</p>
                        <p>Error Rate: 1.5%</p>
                        <p>Most Frequent Query: "What are my upcoming appointments?"</p>
                    </div>
                    {/* Placeholder for other APIs */}
                    <div className="card">
                        <h3>Data Protection API</h3>
                        <p>Total Calls: 2,345</p>
                        <p>Average Response Time: 2.1 seconds</p>
                        <p>Success Rate: 99.2%</p>
                        <p>Error Rate: 0.8%</p>
                    </div>
                </div>
            </section>

            {/* Recent API Activities */}
            <section className="recent-activities">
                <h2>Recent API Activities</h2>
                <div className="activity-list">
                    <div className="activity-item">
                        <p><strong>POST</strong> /api/chat-agent/message</p>
                        <span>5 minutes ago - Success</span>
                    </div>
                    <div className="activity-item">
                        <p><strong>GET</strong> /api/data-protection/status</p>
                        <span>10 minutes ago - Success</span>
                    </div>
                    <div className="activity-item">
                        <p><strong>POST</strong> /api/chat-agent/message</p>
                        <span>15 minutes ago - Error</span>
                    </div>
                </div>
            </section>

            {/* API Errors and Alerts */}
            <section className="api-errors">
                <h2>API Errors and Alerts</h2>
                <div className="errors-list">
                    <div className="error-item">
                        <p><strong>500 Internal Server Error</strong> - /api/chat-agent/message</p>
                        <span>15 minutes ago</span>
                    </div>
                    <div className="error-item">
                        <p><strong>429 Too Many Requests</strong> - /api/data-protection/status</p>
                        <span>1 hour ago</span>
                    </div>
                </div>
            </section>

            {/* Usage Analytics */}
            <section className="usage-analytics">
                <h2>Usage Analytics</h2>
                <div className="analytics-graphs">
                    {/* Placeholder for charts/graphs */}
                    <div className="chart-placeholder">[Graph of API Calls Over Time]</div>
                    <div className="chart-placeholder">[Geographical Distribution of API Calls]</div>
                </div>
            </section>

            {/* User Feedback and Support */}
            <section className="feedback-support">
                <h2>User Feedback and Support</h2>
                <div className="feedback-list">
                    <div className="feedback-item">
                        <p>"The Chat AI Agent API is incredibly responsive and accurate."</p>
                        <span>- Alex Johnson</span>
                    </div>
                    <div className="feedback-item">
                        <p>"We love the data protection features, they are crucial for our operations."</p>
                        <span>- Maria Garcia</span>
                    </div>
                </div>
                <div className="support-tickets">
                    <h3>Open Support Tickets</h3>
                    <div className="ticket-item">
                        <p><strong>Ticket #12345</strong> - Issue with API Rate Limits</p>
                        <span>Status: Open</span>
                    </div>
                </div>
            </section>

            {/* Upcoming Features and Announcements */}
            <section className="upcoming-features">
                <h2>Upcoming Features and Announcements</h2>
                <ul>
                    <li>New endpoint for real-time sentiment analysis</li>
                    <li>Enhanced multi-region API deployment</li>
                    <li>Improved data encryption standards</li>
                </ul>
            </section>
        </div>
    );
};

export default Dashboard;