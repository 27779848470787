import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './ProductsList.css';

const ProductsList = () => {
  return (
    <div className="page-container">
      <Container className="content">
        <h1 className="text-center mb-4">Products</h1>
        <Row className="justify-content-center">
          <Col md={4} className="mb-4">
            <Card className="product-card">
              <Card.Body>
                <Card.Title>OPIUS AI Chatbot</Card.Title>
                <Card.Text>
                  Our advanced chatbot integrates seamlessly with your applications, providing intelligent responses based on a customizable knowledge base.
                </Card.Text>
                <ul>
                  <li>AI-powered natural language processing</li>
                  <li>Customizable responses</li>
                  <li>Integration with various platforms</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="product-card">
              <Card.Body>
                <Card.Title>OPIUS AI Analytics</Card.Title>
                <Card.Text>
                  Leverage AI-driven insights to make data-driven decisions. Our analytics tools help you understand user behavior, track performance, and optimize your operations.
                </Card.Text>
                <ul>
                  <li>Real-time data processing</li>
                  <li>Customizable dashboards and reports</li>
                  <li>Advanced predictive analytics</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="product-card">
              <Card.Body>
                <Card.Title>OPIUS AI Automation</Card.Title>
                <Card.Text>
                  Automate repetitive tasks and streamline your workflows with our AI-powered automation tools. Increase efficiency and reduce operational costs.
                </Card.Text>
                <ul>
                  <li>Automated task management</li>
                  <li>Integration with existing tools</li>
                  <li>Customizable automation workflows</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductsList;
