import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Footer, Home, Navbar, PostLoginNavbar } from './containers';
import { ChatAgent, ContactUs, Blog, Documentation, Invest } from './modules';
import { SignIn, SignUp } from './modules';
import { Dashboard, ProductsList, Billing, Support } from './modules';
import { Profile, Settings } from './modules';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './App.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="App bg-light">
      {isLoggedIn ? <PostLoginNavbar handleLogout={handleLogout} /> : <Navbar />}
      <Routes>
        
        {/* Pre login showcase */}
        <Route path="/" element={<Home />} />
        <Route path="/chatagent" element={<ChatAgent />} />
        <Route path="/contact" element={<ContactUs />} />

        <Route path="/blog" element={<Blog />} />

        <Route path="/invest" element={<Invest />} />
        <Route path="/documentation" element={<Documentation />} />

        {/* Authentication */}
        <Route path="/signin" element={<SignIn onLogin={handleLogin} />} />
        <Route path="/signup" element={<SignUp />} />

        {/* Post login routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<ProductsList />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/support" element={<Support />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />

      </Routes>
      <Footer />
    </div>
  );
};

// Wrap App with Router so that useNavigate works properly
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;