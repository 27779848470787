import React, { useState, useEffect } from 'react';
import opiusAgent from '../../../images/opius_white.png';
import { FaPaperPlane, FaCommentDots, FaMinus } from 'react-icons/fa';
import './ChatWindow.css';

const ChatWindow = ({ sendMessage }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false); // Add this line

  useEffect(() => {
    // Add a welcome message when the component loads
    setMessages([{ type: 'bot', text: 'Hi, my name is Opius Agent! How can I assist you today?' }]);
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessageHandler();
    }
  };

  const sendMessageHandler = async () => {
    const input = document.getElementById('user-input');
    const newMessage = input.value.trim();
    if (newMessage) {
      // Add the user's message to the state
      setMessages(prevMessages => [...prevMessages, { type: 'user', text: newMessage }]);
      input.value = '';

      // Set typing indicator
      setIsTyping(true);

      // Send the message and handle the response
      try {
        const response = await sendMessage(newMessage);
        if (response) {
          // Add the bot's response to the state
          setMessages(prevMessages => [...prevMessages, { type: 'bot', text: response }]);
        }
      } catch (error) {
        // Handle error
        setMessages(prevMessages => [...prevMessages, { type: 'bot', text: 'Sorry, something went wrong. Please try again later.' }]);
      } finally {
        // Remove typing indicator
        setIsTyping(false);
      }
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(prevState => !prevState);
  };

  return (
    <div id="existing-chat-window" className={isMinimized ? 'minimized' : ''}>
      {isMinimized ? (
        <button className="minimize-button" onClick={toggleMinimize}>
          <FaCommentDots />
        </button>
      ) : (
        <div id="chat-container">
          <div className="chat-header">
            <div className="chat-logo-container">
              <img src={opiusAgent} alt="Logo" className="chat-logo" />
              <span className="online-status"></span>
              <h2> Opius Agent</h2>
            </div>
            <button className="minimize-button" onClick={toggleMinimize}>
              <FaMinus />
            </button>
          </div>
          <div id="chat-box">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.type}-message`}
                dangerouslySetInnerHTML={{ __html: message.text }}
              />
            ))}
          </div>
          <div id="typing-indicator">
            {isTyping && (
              <div className="typing-message">Opius Agent is typing<span className="typing-dots">...</span></div>
            )}
          </div>
          <div id="input-box">
            <input
              type="text"
              id="user-input"
              placeholder="Type a message..."
              onKeyPress={handleKeyPress}
            />
            <button onClick={sendMessageHandler} className="send-button">
              <FaPaperPlane />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;