import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import opiousLogo from '../../images/opius_logo.png';
import userAvatar from '../../images/user_avatar.png';
import './postLoginNavbar.css';

const PostLoginNavbar = ({ handleLogout }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="post-login-navbar gradient__bg">
      <div className="post-login-navbar-logo">
        <Link to="/dashboard">
          <img src={opiousLogo} alt="logo" />
        </Link>
      </div>
      <div className="post-login-navbar-links">
        <Link to="/dashboard"><p>Dashboard</p></Link>
        <Link to="/products"><p>Products</p></Link>
        <Link to="/billing"><p>Billing</p></Link>
        <Link to="/support"><p>Support</p></Link>
      </div>
      <div className="post-login-navbar-user">
        <img src={userAvatar} alt="user avatar" />
        <div className="post-login-navbar-user-menu">
          <Link to="/profile"><p>Profile</p></Link>
          <Link to="/settings"><p>Settings</p></Link>
          <Link 
          to="/" 
          onClick={() => { handleLogout(); setToggleMenu(false); }} 
          style={{ textDecoration: 'none', color: 'inherit' }}
          ><p>
            Logout
          </p></Link>
        </div>
      </div>
      <div className="post-login-navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="post-login-navbar-menu_container scale-up-center">
            <div className="post-login-navbar-menu_container-links">
              <Link to="/dashboard" onClick={() => setToggleMenu(false)}><p>Dashboard</p></Link>
              <Link to="/products" onClick={() => setToggleMenu(false)}><p>Products</p></Link>
              <Link to="/billing" onClick={() => setToggleMenu(false)}><p>Billing</p></Link>
              <Link to="/support" onClick={() => setToggleMenu(false)}><p>Support</p></Link>
              <Link to="/profile" onClick={() => setToggleMenu(false)}><p>Profile</p></Link>
              <Link to="/settings" onClick={() => setToggleMenu(false)}><p>Settings</p></Link>
              <Link 
                to="/" 
                onClick={() => { handleLogout(); setToggleMenu(false); }} 
                style={{ textDecoration: 'none', color: 'inherit' }}
              ><p>
                Logout
              </p></Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostLoginNavbar;